import axios from 'axios';
// import { flare } from '@flareapp/flare-client';

let api;

export const statamic = (token) =>
  api ||
  (api = axios.create({
    baseURL: useRuntimeConfig().public.statamicApiUrl,
  }));

export const setPreviewToken = (token) => {
  statamic().interceptors.request.use(
    (config) => {
      config.headers = config.headers || {};
      config.headers['X-Statamic-Token'] = token;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

export const collectionList = async ({ collection, fields, filters, limit, page, sorting }) => {
  try {
    const response = await statamic().get(
      `/collections/${collection}/entries`,
      formatParams({ fields, filters, limit, page, sorting }),
    );

    return response.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const taxonomyList = async ({ taxonomy, filters, limit, page, sorting }) => {
  try {
    const response = await statamic().get(
      `/taxonomies/${taxonomy}/terms`,
      formatParams({ filters, limit, page, sorting }),
    );

    return response.data.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const entry = async ({ collection, slug, id, site }) => {
  // statamic().interceptors.response.use(
  //   (res) => {
  //     console.log(res.request._header);
  //     return res;
  //   },
  //   (error) => Promise.reject(error),
  // );
  // console.log('hey');

  if (!slug && !id) {
    return null;
  }

  if (slug) {
    return entryBySlug({ collection, slug, site});
  }

  if (id) {
    return entryById({ collection, id });
  }
};

export const entryById = async ({ collection, id }) => {
  try {
    const response = await statamic().get(`collections/${collection}/entries/${id}`);

    return response.data.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const entryBySlug = async ({ collection, slug, site }) => {
  try {
    const filters = [
      {
        field: 'slug',
        value: slug,
      },
      {
        field: 'site',
        value: site,
      },
    ];

    const data = await collectionList({ collection, filters });

    return data.data?.pop();
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const navigationTree = async ({ navigation, fields, max_depth, site }) => {
  try {
    const response = await statamic().get(`navs/${navigation}/tree`, formatParams({ fields, max_depth, site }));

    return response.data.data;
  } catch (error) {
    flare.report(error);
    console.error(error);

    return null;
  }
};

export const layout = async ({ fields, max_depth, site }) => {
  try {
    const response = await statamic().get('layout', formatParams({ fields, max_depth, site }));

    return response.data.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const statamicSearch = async ({ query }) => {
  try {
    const response = await statamic().get('search', { params: { q: query } });

    return response.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

export const statamicRequest = async ({ uri }) => {
  try {
    const response = await statamic().get(uri);

    return response.data.data;
  } catch (error) {
    // flare.report(error);
    console.error(error);

    return null;
  }
};

function formatParams({ filters, fields, limit, page, sorting, max_depth, site }) {
  const params = {};

  if (filters) {
    params.filter = {};
    filters?.forEach((filter) => {
      if (filter.taxonomy === true) {
        const filterField = filter.condition
          ? `taxonomy:${filter.field}:${filter.condition}`
          : `taxonomy:${filter.field}`;
        params.filter[filterField] = filter.value;
      } else {
        const filterField = filter.condition ? `${filter.field}:${filter.condition}` : `${filter.field}`;
        params.filter[filterField] = filter.value;
      }
    });
  }

  if (fields) {
    params.fields = fields.join(',');
  }

  if (limit && page) {
    params.page = page;
    params.limit = limit;
  }

  if (sorting) {
    params.sort = sorting?.map((sort) => (sort.direction === 'asc' ? sort.field : `-${sort.field}`)).join(',');
  }

  if (max_depth) {
    params.max_depth = max_depth;
  }

  if (site) {
    params.site = site;
  }

  return { params };
}
