import { defineStore } from 'pinia';
import { entry } from '~/pinkyellow/satanic-api';
import md5 from 'md5';

export const usePageStore = defineStore('page', {
  state: () => ({
    page: null,
    pages: {},
    lastKey: ''
  }),

  getters: {
    getPage(state) {
      return state.page;
    },

    getPageBySlug(state) {
      return (slug) => {
        const key = md5(slug);
        return state.pages[key];
      };
    },

    getBlueprintHandle(state) {
      return state.page ? state.page.blueprint.handle : '';
    },

    refetchPage(state) {
      return (slug) => {
        const key = md5(slug);
        return state.lastKey !== key;
      };
    }
  },

  actions: {
    async fetchPage({ collection, slug, token }) {
      let key = md5(slug);

      let page = await entry({
        collection: collection,
        slug: slug,
        token: token,
        site: 'default'
      });

      if (!page) {
        throw createError({
          statusCode: 404,
          statusMessage: 'Page Not Found',
          fatal: true,
        });
      }

      this.page = page;
      this.pages[key] = page;
      this.lastKey = key;
    },
  },
});
